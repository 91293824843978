import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Offerings from '../Offerings'
import PersonalityQuotes from '../PersonalityQuotes'
import PropTypes from 'prop-types'
import prices from '../../assets/img/prices.jpg'
import trump from '../../assets/img/trump.png'
import joeexotic from '../../assets/img/joeexotic.png'

const AboutPageTemplate = ({
  title,
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
  personalityquotes,
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-three-fifths is-offset-one-fifth'>
              <div className='section'>
                <h1 className='title is-size-1 has-text-weight-bold'>
                  {title}
                </h1>
              </div>
            </div>
          </div>

          <div className='column is-10 is-offset-1'>

            <div className='columns is-variable is-8 has-text-centered'>

              <div className='column'>
                <div className='sprite aboutSprite'>
                  <img className='one' src={trump} alt='Bold Bastards Build Bigger Brands' />
                </div>
                <p className='is-size-5'>Stand out for free by giving your brand a killer personality.</p>
                <span className='mt-3 tag is-primary is-rounded'>Awareness</span>
              </div>
              <div className='column'>
                <div className='sprite aboutSprite'>
                  <img className='two' src={trump} alt='Bold Bastards Build Bigger Brands' />
                </div>
                <p className='is-size-5'>Convert more with remarkable + consistent messaging.</p>
                <span className='mt-3 tag is-primary is-rounded'>Loyalty</span>
              </div>
              <div className='column'>
                <div className='sprite aboutSprite'>
                  <img className='three' src={trump} alt='Bold Bastards Build Bigger Brands' />
                </div>
                <p className='is-size-5'>Grow an audience that gives a sh*t by saying what you mean.</p>
                <span className='mt-3 tag is-primary is-rounded'>Advocacy</span>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>

    <section>


      <div className='container box has-text-centered px-6'>
        
        <h2 className='is-size-3 has-text-weight-bold py-5'>It's how you build an authentic, inspiring brand.</h2>
        <aside className='columns py-5 is-variable is-8'>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>Define your difference</h3>
            <p>Your marketing will only work when you find yours. A Bold Rewrite will explore your opportunties and offer you a fresh + unique perspective.</p>
          </div>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>Highlight your difference</h3>
            <p>A Bold Rewrite isn't just advice on what you could do – it's an example of how to do it. So you can see, and feel a new way of pitching your startup.</p>
          </div>

          <div className='column'>
            <h3 className='has-text-weight-bold is-size-5 mb-3'>Amplify your difference</h3>
            <p>You're solving a unique problem with your business. Boldly presenting yours will make you louder and more authentic to the people that matter.</p>
          </div>

        </aside>

      </div>

    </section>

    <section className='section'>
      <div className='container'>

        <div className='column is-8 is-offset-2 content'>
          <h3 className='has-text-weight-semibold is-size-2'>
            {heading}
          </h3>
          <p className='is-size-5'>{description}</p>

          <div className="image is-16by9">
            <iframe className='has-ratio mt-6' src="https://muse.ai/embed/72jbbsA?search=0&links=0&logo=0" width="576" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>

      </div>
    </section>

    <PersonalityQuotes personalityquotes={personalityquotes} />

    <section className='section'>
      <div className='container'>
        <div className='column is-10 is-offset-1'>
          <div className='columns is-variable is-8'>
            <div className='column'>
              <img src={prices} />
            </div>
            <div className='content column'>
              <h2>Now it's your turn to disrupt.</h2>
              <p>Together we'll reflect your purpose with your personality; so your audience stops ignoring your ads and starts spreading your name.</p>
              <p>This is <em>step #1 creative direction</em>, because:</p>
              <ul>
                <li>You need expert creative help that understands your world</li>
                <li>You need to stand out and gain more traction with your audience</li>
                <li>You need help that's autonomous, so you're not 'blocking' progress</li>
                <li>You need great value for money</li>
              </ul>

              <p>No hourly rates + no monthly subscriptions + no bullsh*t.</p>
              <p>Just fresh perspective, and polarising creative at a fraction of the cost.</p>

              <Link
                className='button is-primary'
                to='/order'>
                  Book Yours Now
              </Link>

            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
)

AboutPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  personalityquotes: PropTypes.array,

}

export default AboutPageTemplate
