import React from 'react'
import PropTypes from 'prop-types'
import Carousel from 'nuka-carousel'
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 901 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 701, maxWidth: 900 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 700 })
  return isMobile ? children : null
}

const PersonalityQuotes = ({ personalityquotes }) => (


  <section className='container mb-6 section'>

    <div className='column is-8 is-offset-2 has-text-centered mb-6'>
      <h2 className='has-text-weight-bold is-size-5'>Here's what other ambitious startups and founders have gained:</h2>
    </div>

    <div className='columns is-variable is-8'>

      <Desktop>
        <Carousel
        slidesToShow={3}
        slidesToScroll={1}
        transitionMode="fade"
        renderCenterLeftControls={({ previousSlide }) => (null)}
        renderCenterRightControls={({ nextSlide }) => (null)}
        >
        {personalityquotes.map((pquote, id) => (
          <div className='column'>
            <article className='message px-4 py-4' key={id}>
              <div className='message-body'>
                <img width='50%' src={pquote.logo} alt={pquote.company} />
                <p className='is-size-4 mb-5'>{pquote.quote}</p>
                <cite>
                  <p>
                    <span className='has-text-weight-bold'>{pquote.author}</span>
                  </p>
                </cite>
              </div>
            </article>
          </div>
        ))}
        </Carousel>
      </Desktop>

      <Tablet>
        <Carousel
          slidesToShow={2}
          slidesToScroll={1}
          transitionMode="fade"
          renderCenterLeftControls={({ previousSlide }) => (null)}
          renderCenterRightControls={({ nextSlide }) => (null)}
        >
        {personalityquotes.map((pquote, id) => (
          <div className='column'>
            <article className='message px-4 py-4' key={id}>
              <div className='message-body'>
                <img width='50%' src={pquote.logo} alt={pquote.company} />
                <p className='is-size-4 mb-5'>{pquote.quote}</p>
                <cite>
                  <p>
                    <span className='has-text-weight-bold'>{pquote.author}</span>
                  </p>
                </cite>
              </div>
            </article>
          </div>
        ))}
        </Carousel>
      </Tablet>

      <Mobile>
        <Carousel
          slidesToShow={1}
          slidesToScroll={1}
          transitionMode="fade"
          renderCenterLeftControls={({ previousSlide }) => (null)}
          renderCenterRightControls={({ nextSlide }) => (null)}
        >
        {personalityquotes.map((pquote, id) => (
          <div className='column'>
            <article className='message px-4 py-4' key={id}>
              <div className='message-body'>
                <img width='50%' src={pquote.logo} alt={pquote.company} />
                <p className='is-size-4 mb-5'>{pquote.quote}</p>
                <cite>
                  <p>
                    <span className='has-text-weight-bold'>{pquote.author}</span>
                  </p>
                </cite>
              </div>
            </article>
          </div>
        ))}
        </Carousel>
      </Mobile>
    </div>
  </section>
)

PersonalityQuotes.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      quote: PropTypes.string,
      author: PropTypes.string,
      company: PropTypes.string,
    })
  ),
}

export default PersonalityQuotes
